.styledToastError {
  border-radius: 0.25rem !important;
  color: #721c24 !important ;
  background-color: #f8d7da !important ;
  border-color: #f5c6cb !important ;
}

.styledToastSucces {
  border-radius: 0.25rem !important;
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.styledToastInfo {
  border-radius: 0.25rem !important;
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}
